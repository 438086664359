import React, {Component, createContext} from "react";
import translateJSON from "../content/translate.json";

export const LanguageContext = createContext("en");

const currentLangs = ["ar", "en"];

class LanguageProvider extends Component {
    constructor(props) {
        super(props);

        const langInPath = this.getLangWithPathname().langInPath || "ar";
        const dir = this.getDirByLang(langInPath);
        this.fixUrl();

        this.state = {
            lang: langInPath,
            translate: translateJSON[langInPath],
            dir,
        };
    }

    getDirByLang = (lang = "ar") => {
        return lang === "ar" ? "rtl" : "ltr";
    }

    getLangWithPathname = () => {
        const { pathname } = window.location;
        const splitedPathname = pathname.split("/");
        const langInPath = splitedPathname.find((path) => {
            const findLang = currentLangs.find((currentLang) => currentLang === path);
            return findLang !== undefined;
        });
        return { langInPath, splitedPathname };
    }

    fixUrl = () => {
        const { langInPath, splitedPathname } = this.getLangWithPathname();
        if (langInPath === "ar") {
            const filteredSplitedPathnameByArlang = splitedPathname.filter((path) => path !== "ar");
            const newPathname = filteredSplitedPathnameByArlang.join("/");
            window.history.pushState({}, "", window.location.origin + newPathname);
        }
    }

    toggleLang = (lang, push) => {
        if (!lang && !push) return;

        const { pathname } = window.location;
        const splitedPathname = pathname.split("/");
        const newPathName = splitedPathname.filter((path) => path !== "ar" && path !== "en").join("/");
        const langForUrl = lang === "ar" ? "" : `/${lang}`;
        const newUrl = `${langForUrl}${newPathName}`;

        push(newUrl);
        const dir = this.getDirByLang(lang);
        document.documentElement.lang = lang;
        const translate = translateJSON[lang];
        this.setState({lang, translate, dir});
    };

    getTranslate = (key) => {
        let {translate} = this.state;
        if (key === undefined) return "";
        if (typeof key === "boolean") return key;
        const keys = key.split(".");
        const keysLength = keys.length;
        if (keysLength < 1) return key;
        for (let index = 0; index < keysLength; index++) {
            if (!translate?.[keys[index]]) return key;
            translate = translate[keys[index]];
        }
        return translate;
    };

    render() {
        return (
            <LanguageContext.Provider value={{
                ...this.state,
                toggleLang: this.toggleLang.bind(this),
                getTranslate: this.getTranslate.bind(this),
            }}>
                {this.props.children}
            </LanguageContext.Provider>
        )
    }
}

export default LanguageProvider;
