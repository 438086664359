import React, { useContext } from 'react';
import loadable from "@loadable/component";
import { LanguageContext } from "./contexts/LanguageContext";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Sprite from "./components/atoms/svg-sprite/Sprite";

import './App.scss';

const Main = loadable(() => import("./pages/main"));

const App = () => {
  const { lang } = useContext(LanguageContext);
  const langPath = lang === "ar" ? "/" : `/${lang}/`;

  return (
    <>
      <Sprite />
      <Router>
        <Switch>
          <Route path={`${langPath}:region`} component={Main} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
